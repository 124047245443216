@mixin box-shadow($string)
{
    -webkit-box-shadow: $string;
       -moz-box-shadow: $string;
            box-shadow: $string;
}

@mixin border-radius($radius: 2px)
{
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
            border-radius: $radius;
}

@mixin border-radiuses($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0)
{
    -webkit-border-top-right-radius: $topright;
    -webkit-border-bottom-right-radius: $bottomright;
    -webkit-border-bottom-left-radius: $bottomleft;
    -webkit-border-top-left-radius: $topleft;
    -moz-border-radius-topright: $topright;
    -moz-border-radius-bottomright: $bottomright;
    -moz-border-radius-bottomleft: $bottomleft;
    -moz-border-radius-topleft: $topleft;
    border-top-right-radius: $topright;
    border-bottom-right-radius: $bottomright;
    border-bottom-left-radius: $bottomleft;
    border-top-left-radius: $topleft;
}

@mixin rotate($deg)
{
    -webkit-transform: rotate($deg);
       -moz-transform: rotate($deg);
        -ms-transform: rotate($deg);
            transform: rotate($deg);
}

@mixin skew($deg, $deg2)
{
    -webkit-transform: skew($deg, $deg2);
       -moz-transform: skew($deg, $deg2);
        -ms-transform: skew($deg, $deg2);
            transform: skew($deg, $deg2);
}

@mixin translate($x, $y:0)
{
    -webkit-transform: translate($x, $y);
       -moz-transform: translate($x, $y);
        -ms-transform: translate($x, $y);
            transform: translate($x, $y);
}

@mixin translate3d($x, $y:0, $z:0)
{
    -webkit-transform: translate3d($x, $y, $z);
       -moz-transform: translate3d($x, $y, $z);
        -ms-transform: translate3d($x, $y, $z);
            transform: translate3d($x, $y, $z);
}

@mixin scale($factor)
{
    -webkit-transform: scale($factor);
       -moz-transform: scale($factor);
        -ms-transform: scale($factor);
            transform: scale($factor);
}

@mixin perspective($value:1000)
{
    -webkit-perspective: $value;
       -moz-perspective: $value;
        -ms-perspective: $value;
            perspective: $value;
}

@mixin transition($transition)
{
    -webkit-transition: $transition;  
       -moz-transition: $transition;
        -ms-transition: $transition;  
            transition: $transition;
}

@mixin transform-origin($x:center, $y:center)
{
    -webkit-transform-origin: $x $y;
       -moz-transform-origin: $x $y;
        -ms-transform-origin: $x $y;
            transform-origin: $x $y;
}

@mixin horizontal-gradient($startColor: #555, $endColor: #333)
{
    background-image: -webkit-gradient(linear, left top, right top, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient(right center, $startColor, $endColor);
    background-image: -moz-linear-gradient(right center, $startColor, $endColor);
}

@mixin horizontal-three-color-gradient($firstStop: 0, $startColor: #00b3ee, $midStop: 0.5, $midColor: #7a43b6, $lastStop: 1, $endColor: #c3325f)
{
    background-repeat: no-repeat;
    background-image: -webkit-gradient(linear, left top, right top, 
        color-stop($firstStop, $startColor), 
        color-stop($midStop, $midColor), 
        color-stop($lastStop, $endColor));
    background-image: -webkit-linear-gradient(
        $startColor $firstStop, 
        $midColor $midStop, 
        $endColor $lastStop);
    background-image: -moz-linear-gradient(
        $startColor $firstStop, 
        $midColor $midStop, 
        $endColor $lastStop);
}

@mixin horizontal-four-color-gradient($firstStop: 0, $startColor: #00b3ee, $secondStop: 0, $secondColor: #00b3ee, $midStop: 0.5, $midColor: #7a43b6, $lastStop: 1, $endColor: #c3325f)
{
    background-repeat: no-repeat;
    background-image: -webkit-gradient(linear, left top, right top, 
        color-stop($firstStop, $startColor), 
        color-stop($secondStop, $secondColor), 
        color-stop($midStop, $midColor), 
        color-stop($lastStop, $endColor));
    background-image: -webkit-linear-gradient(
        $startColor $firstStop,
        $secondColor $secondStop, 
        $midColor $midStop, 
        $endColor $lastStop);
    background-image: -moz-linear-gradient(
        $startColor $firstStop, 
        $secondColor $secondStop,
        $midColor $midStop, 
        $endColor $lastStop);
}

@mixin vertical-gradient($startColor: #555, $endColor: #333)
{
    background-image: -webkit-gradient(linear, left top, left bottom, from($startColor), to($endColor));
    background-image: -webkit-linear-gradient($startColor, $endColor);
    background-image: -moz-linear-gradient($startColor, $endColor);
}

@mixin vertical-three-color-gradient($startColor: #00b3ee, $colorStop: 0.5, $midColor: #7a43b6, $endColor: #c3325f)
{
    background-image: -webkit-gradient(linear, 0 0, 0 100%, from($startColor), color-stop($colorStop, $midColor), to($endColor));
    background-image: -webkit-linear-gradient($startColor, color-stop($colorStop, $midColor), $endColor);
    background-image: -moz-linear-gradient($startColor, color-stop($midColor, $colorStop), $endColor);
}

@mixin column-count($count, $gutter)
{
       -moz-column-count: $count;
         -moz-column-gap: $gutter;
    -webkit-column-count: $count;
      -webkit-column-gap: $gutter;
            column-count: $count;
              column-gap: $gutter;
}