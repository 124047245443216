tokens {
    display: block;
}

.tokens_list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.tokens_item_pre {
    margin: 0;
    flex: 1;
}

.tokens_item_description {
    padding: .5em;
    margin: 0 .5em;
    flex: 1;
}

.tokens_list {
    padding: 0.5em 0 2em 0;
}

.tokens_form {

}

.tokens_errors {
    clear: both;
}

@media all and (max-width: 55em) {
    .tokens_list {
        flex-direction: column;
        align-items: flex-start;
    }
}
