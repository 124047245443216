@import 'functions';

$font-size: 15;
$default-size: 16;
$line: 24;
$em: $font-size*1em;
$column: 48;
$gutter: 24;

@import 'common';

$colorLink:             #191970;
$colorTitle:            #000;
$colorText:             #333;
$colorHeader:           #444;
$colorHeaderBorder:     #ddd;
$colorCodeBorder:       #eaeaea;
$colorCodeBackgorund:   #f8f8f8;
$colorTableBorder:      #eaeaea;
$colorFooterLink:       #666;
$colorFooterBorder:     #ddd;
$colorNote:             #666;
$colorButton:           #444;
$colorButtonBackground: #ddd;
$colorButtonBorder:     #999;
$colorInputBorder:      #ccc;
$colorInputBorderError: red;
$colorBackground:       #fff;
$colorAppBackground:    #ccc;
$colorAppPlaceholder:   #999;
$colorSwitcherBorder:   #ccc;
$colorSwitcherDisabled: #999;
$colorTocBorder:        #ddd;
$colorTocBackground:    #fafafb;
$colorTocBackgroundAlt: #f0f0f3;
$colorTocColor:         #999;
$colorTocColorAlt:      #666;

body {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: $font-size / $default-size * 1em;
    line-height: $line / $font-size * 1em;
    margin: 0 auto;
    max-width: $cols10;
    color: $colorText;
    padding: 0 2em;
}

p {
    line-height: 1.5em;
}

h1, h2, h3, h4 {
    padding: 1em 0;
    font-weight: normal;
}

#vp h1 { line-height: 1.4em; }

h3 { padding: 0.5em 0; }
h4 { padding: 0.5em 0 0 0; font-weight: bold; }

a, a:visited {
    color: $colorLink;
    text-decoration: underline;
}

header {
    float: left;
    width: 100%;
    border-bottom: 1px solid $colorHeaderBorder;
}

header h1 a {
    display: block;
    overflow: hidden;
    background: url(../images/logo.png) no-repeat;
    width: 205px;
    height: 30px;
    text-indent: -99999px;
}

header h1 {
    padding: 0.5em 0;
    float: left;
}

header li {
    float: left;
    list-style: none;
}

header li a, header li a:visited {
    color: #999;
    text-decoration: none;
    font-weight: bold;
}

header li a:hover {
    color: #666;
}

acronym {
    border-bottom: 1px dotted gray;
    cursor: help;
}

article, section {
    clear: both;
    padding-bottom: 1em;
}

article h1, section h1 {
    font-size: 1.9em;
}

article ul, article ol, section ul, section ol, table, pre {
    margin: 0 0 1em 0;
}

article ul, article ol, section ul, section ol {
    margin-left: 2.3em;
}

article > p, section > p {
    padding-bottom: 1em;
}

p.note {
    border-top: 1px solid $colorCodeBorder;
    border-bottom: 1px solid $colorCodeBorder;
    padding: 0.5em 0;
    margin: 0 0 1em 0;
}

pre, .highlighter-rouge pre {
    @include border-radius(5px);
    border: 1px solid $colorCodeBorder;
    background: $colorCodeBackgorund;
    padding: 0.5em;
    font-size: 1.1em;
    display: block;
}

code {
    border-top: 1px solid $colorCodeBorder;
    border-bottom: 1px solid $colorCodeBorder;
    background: $colorCodeBackgorund;
    padding: 0.2em;
    font-size: 1.1em;
}

pre > code {
    border: 0;
    padding: 0;
    font-size: 1em;
}

pre, code {
    font-familiy: Courier, monospace;
}

blockquote {
    border-left: 0.3em solid $colorInputBorder;
    padding-left: 1em;
    color: gray;
}

table {
    border-bottom: 1px solid $colorTableBorder;
    width: 100%;
}

tr {
    border-top: 1px solid $colorTableBorder;
}

tr:hover {
    background: #fffeee;
}

td {
    vertical-align: top;
    width: 50%;
    padding: 1em 0;
}

td .note {
    font-size: 0.85em;
    color: $colorNote;
    position: relative;
    top: -0.1em;
}

tr.level1, tr.level2 {
    border-left: 5px solid $colorTableBorder;
}

tr.level1 td:first-child {
    padding-left: 1em;
}

tr.level2 td:first-child {
    padding-left: 2em;
}

ul.toc {
    padding: 0.5em 0 0.5em 0;
}

ul.toc li {
    margin-bottom: 0.5em;
}

aside > ul {
    margin: 1.7em 0;
    margin-left: 0;
}

aside li {
    list-style: none;
}

aside img {
    max-width: 100%;
}

img.right {
    float: right;
}

footer {
    clear: both;
    margin: 2em 0;
    padding: 1em 0 3em 0;
    border-top: 1px solid $colorFooterBorder;
}

footer ul {
    float: left;
}

footer li {
    float: left;
    list-style: none;
    margin-right: 1em;
    color: $colorFooterLink;
    font-size: 0.85em;
}

footer a, footer a:visited {
    color: $colorFooterLink;
}

#vp h1 {
    font-size: 3em;
}

#more { clear: both; }

#start h2 {
    padding: 1em 0 0.4em 0;
}

#blog {
    margin-bottom: 1em;
}

#blog ul {
    margin: -0.5em 0 0;
}

#blog li {
    list-style: none;
}

#blog h3 {
    padding-bottom: 0;
}

#blog li > em {
    color: gray;
    font-size: 0.85em;
    margin: -0.3em 0 0;
    display: block;
}

#apps.list h1 {
    float: left;
}

#new a, .button, .button:visited {
    @include border-radius(5px);
    background: $colorButtonBackground;
    color: $colorButton;
    padding: 0.5em 2em;
    text-decoration: none;
    border: 1px solid $colorButtonBorder;
}

#new a {
    font-size: 0.6em;
}

#new {
    float: right;
}

#apps ul {
    clear: both;
}

#apps table {
    margin-bottom: 2em;
}

#apps li {
    list-style: none;
    border-bottom: 1px solid $colorInputBorder;
    padding: 0 0 1em 0;
}

#apps li:last-child {
    border-bottom: none;
}

#apps ul {
    margin-left: 0;
}

#apps img {
    float: right;
}

form {
    width: 100%;
    max-width: 35em;
    float: left;
    padding: 0 0 2em 0;
}

input, textarea {
    @include border-radius(5px);
    border: 1px solid $colorInputBorder;
    padding: 0.5em;
    margin: 0.5em 0;
    width: 100%;
}

input[type="checkbox"] {
    width: auto;
    margin-right: 0.5em;
}

label {
    margin-right: 0.5em;
}

input[type="submit"], button {
    @include border-radius(5px);
    border: 1px solid $colorButtonBorder;
    background-color: $colorButtonBackground;
    color: $colorButton;
    width: auto;
    padding: 0.5em 2em;
}

#clientform label {
    display: block;
}

input.error {
    border-color: $colorInputBorderError;
}

form dd p {
    color: gray;
    font-size: 0.65em;
    padding-bottom: 1em;
}

form > p {
    padding-bottom: 2em;
}

#toc {
    border: 1px solid $colorTocBorder;
}

#toc li a {
    display: block;
    padding: 0.5em;
    border-bottom: 1px solid $colorTocBorder;
    background: $colorTocBackground;
    color: $colorTocColor;
    text-decoration: none;
}

#toc li a:hover {
    background: $colorTocBackgroundAlt;
    color: $colorTocColorAlt;
}

#toc > li:last-child a {
    border-bottom: 0;
}

#toc > li {
    font-weight: bold;
}

#toc ul > li {
    font-weight: normal;
}

#toc ul > li a {
    background: #fff;
}

#toc ul {
    display: none;
    margin-bottom: 0;
}

#toc ul.open {
    display: block;
}

#toc ul ul {
    padding-left: 0.5em;
}

time {
    font-size: 0.8em;
    color: $colorNote;
    font-style: italic;
}

ul.switcher {
    float: left;
    width: 100%;
    padding-left: 0;
    margin-left: 0;
    border-bottom: 1px solid $colorSwitcherBorder;
}

ul.switcher li {
    float: left;
    list-style: none;
    padding: 0.5em;
    border: 1px solid #fff;
    border-bottom: 1px solid $colorSwitcherBorder;
    position: relative;
    top: 1px;
}

ul.switcher li a {
    color: $colorSwitcherDisabled;
}

ul.switcher li.shown a {
    color: $colorLink;
}

ul.switcher li.shown {
    border: 1px solid $colorSwitcherBorder;
    border-bottom: 1px solid #fff;
}

section.switcher {
    display: none;
}

section.shown {
    display: block;
}

/* 576px */
@media all and (min-width: 36em) {
    body {
        max-width: $cols13;
        background: white;
    }

    .longlist { 
        @include column-count(3, 1em); 
    }

    .widelist {
        @include column-count(4, 1em)
    }

    header nav {
        float: right;
        margin-top: 0;
    }

    header li {
        margin-left: 1em;
        padding-top: 1.1em;
    }

    td:first-child {
        padding-bottom: 1em;
    }

    article {
        float: left;
        width: 75%
    }

    aside {
        float: right;
        width: 23%;
    }

    #vp h1 {
        width: 50%;
        float: left;
    }

    #vp img {
        float: left;
    }

    #vp h1 strong {
        display: block;
    }

    #start, #blog {
        float: left;
        width: 50%;
        clear: none;
    }
}

/* 460px */
@media all and (min-width: 28.75em) and (max-width: 35em) {
    body {
        max-width: $cols6;
    }

    td {
        display: table-cell;
        width: 50%;
    }

    article, aside {
        float: none;
        width: 100%;
    }

    #vp h1 {
        width: 100%;
        float: none;
    }
}

@media all and (min-width: 40em) and (max-width: 55em) {
    #vp img { width: 50%; padding-top: 2em; }
}

@media all and (min-width: 20em) and (max-width: 40em) {
    #vp h1 { width: 100%; font-size: 1.8em;}
    #vp img { max-width: 100% }
}

@media all and (min-width: 20em) and (max-width: 36em) {
    header nav {
        clear: left;
        float: left;
        padding-bottom: 0.7em;
        margin-top: -0.7em;
    }

    header h1 {
        padding-bottom: 0.5em;
    }

    header li {
        margin-left: 0em;
        margin-right: 1em;
        padding-top: 0;
        font-size: 0.9em;
    }
}

@media all and (min-width: 20em) and (max-width: 28.75em) {
    body {
        max-width: $cols5;
    }

    .longlist {
        @include column-count(1, 1em);
    }

    td {
        display: block;
        width: 100%;
    }

    td:first-child {
        padding-bottom: 0;
    }

    footer li {
        padding-right: 0.1em;
    }
}

@import 'syntax';
@import 'tokens-app';
