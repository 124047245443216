   $col1:( 1 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols1: $col1;
  $cols2:( 2 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols3:( 3 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols4:( 4 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols5:( 5 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols6:( 6 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols7:( 7 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols8:( 8 * ($column + $gutter) - $gutter) / $font-size*1em;
  $cols9:( 9 * ($column + $gutter) - $gutter) / $font-size*1em;
$cols10: (10 * ($column + $gutter) - $gutter) / $font-size*1em;
$cols11: (11 * ($column + $gutter) - $gutter) / $font-size*1em;
$cols12: (12 * ($column + $gutter) - $gutter) / $font-size*1em;
$cols13: (13 * ($column + $gutter) - $gutter) / $font-size*1em;
$cols14: (14 * ($column + $gutter) - $gutter) / $font-size*1em;
$cols15: (15 * ($column + $gutter) - $gutter) / $font-size*1em;
$cols16: (16 * ($column + $gutter) - $gutter) / $font-size*1em;

@mixin width($cols:1)
{
    width: ($cols * ($column + $gutter) - $gutter) / $em;
}

body, div,
h1, h2, h3, h4, h5, h6,
p, blockquote, pre, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, th, td,
article, aside, figure, footer, header, hgroup, menu, nav, section {
    margin: 0;
    padding: 0;
    border: 0;
}

html {
    height: 100%; 
    -webkit-text-size-adjust: 100%; 
    -ms-text-size-adjust: 100%;
}
body {
    min-height: 100%; 
    font-size: 100%;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, nav, section, audio, canvas, video {
    display: block;
}
sub, sup {
    font-size: 75%; 
    line-height: 0; 
    position: relative; 
    vertical-align: baseline;
}
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
pre {
    white-space: pre; 
    white-space: pre-wrap; 
    word-wrap: break-word;
}
b, strong {
    font-weight: bold;
}
abbr[title] {
    border-bottom: 1px dotted;
}
input, textarea, button, select {
    margin: 0;
    font-size: 100%;
    line-height: normal;
    vertical-align: baseline;
}
button,
html input[type="button"], 
input[type="reset"], 
input[type="submit"] {
    cursor: pointer;
    -webkit-appearance: button;
}
input[type="checkbox"],
input[type="radio"] {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
         -o-box-sizing: border-box;
        -ms-box-sizing: border-box;
            box-sizing: border-box;
}
textarea {
    overflow: auto;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a img, img {
    -ms-interpolation-mode: bicubic;
    border: 0;
}
